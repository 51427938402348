<template>
  <div>
    <v-card-title class="text-h5">
      캐러셀 {{ Number($route.params.uid) ? '수정' : '등록' }}
      <v-spacer />
    </v-card-title>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6 mb-3">
            캐러셀 노출 여부
            <span class="ml-4" style="font-size: 13px">
              복수 선택이 불가능합니다.
            </span>
          </v-list-item-title>
          <v-btn-toggle v-model="status" color="deep-purple accent-3">
            <v-btn depressed class="pa-5" value="show">
              노출
            </v-btn>
            <v-btn depressed class="pa-5" value="keep">
              비노출
            </v-btn>
          </v-btn-toggle>
          <v-list-item-title class="text-h6 mb-3 mt-6">
            노출 콘텐츠 선택
            <span class="ml-4" style="font-size: 13px">
              복수 선택이 가능합니다.
            </span>
          </v-list-item-title>
          <v-btn-toggle v-model="showType" color="deep-purple accent-3" multiple>
            <v-btn depressed class="pa-5" :text="showType !== ['column']" value="column">
              칼럼
            </v-btn>
            <v-btn depressed class="pa-5" :text="showType !== ['report']" value="report">
              레포트
            </v-btn>
          </v-btn-toggle>
          <v-list-item-title class="text-h6 mt-6">
            타이틀 문구
            <span class="ml-4" style="font-size: 13px">
              캐러셀의 제목이 되는 문구를 15자 이내로 적어주세요.
            </span>
          </v-list-item-title>
          <div>
            <v-text-field
              v-model="carousel.title"
              class="mt-2"
              :counter="15"
              placeholder="콘텐츠 캐러셀의 타이틀 문구를 적어주세요 (15자 이하)"
              outlined
              hide-details
              dense
            />
          </div>
          <v-list-item-title class="text-h6 mt-6">
            투명라벨
            <span class="ml-4" style="font-size: 13px">
              캐러셀에 노출하고 싶은 투명라벨을 추가해주세요. (하나의 투명라벨만 등록 가능합니다.)
            </span>
          </v-list-item-title>
          <ObjectInvisibleStampInput v-model="carousel.InvisibleStamp" :multiple="false" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-card-actions>
      <v-spacer />
      <v-btn color="error" large dark @click="exit()">취소하기</v-btn>
      <v-btn color="primary" large dark @click="createCarousel">{{ $route.params.uid ? '수정' : '추가' }}하기</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs, watch } from '@vue/composition-api'
import ObjectInvisibleStampInput from '@/components/input/ObjectInvisibleStamp.vue'

export default defineComponent({
  components: {
    ObjectInvisibleStampInput,
  },

  setup(props, { root }) {
    const state = reactive({
      status: '',
      date: null,
      time: null,
      showType: [],
      all: false,
      publishedAt: null,
      carousel: {
        index: null,
        id: null,
        title: null,
        showType: [],
        InvisibleStamp: null,
        publishedAt: null,
        updatedAt: null,
      },
    })

    const getCarousel = async () => {
      const carousel = await root.$store.dispatch('carousel/getCarousel', {
        id: Number(root.$route.params.uid),
      })
      state.carousel = { ...carousel }
      console.log(carousel)

      state.carousel.title = carousel.title
      state.carousel.showType = carousel.showType
      state.carousel.InvisibleStamp = carousel.InvisibleStamp
      state.carousel.publishedAt = carousel.publishedAt

      if (state.carousel.publishedAt) {
        const date = new Date(state.carousel.publishedAt)
        const Y = String(date.getFullYear())
        const M = String(date.getMonth() + 1).padStart(2, '0')
        const D = String(date.getDate()).padStart(2, '0')
        const hh = String(date.getHours()).padStart(2, '0')
        const mm = String(date.getMinutes()).padStart(2, '0')
        state.date = Y + '-' + M + '-' + D
        state.time = hh + ':' + mm
        if (new Date(state.carousel.publishedAt) <= new Date()) {
          state.status = 'show'
        }
      } else {
        state.status = 'keep'
      }

      state.carousel.showType.includes('column')
        ? (state.showType = ['column'])
        : state.carousel.showType.includes('report')
        ? (state.showType = ['report'])
        : state.carousel.showType == 'all'
        ? (state.showType = ['column', 'report'])
        : undefined
    }

    const createCarousel = async () => {
      try {
        if (root.$route.params.uid) return updateCarousel()
        let date = null
        if (state.date && state.time) date = new Date(state.date + 'T' + state.time)
        else if (state.date) date = new Date(state.date + 'T00:00')

        if (state.status === 'keep') state.carousel.publishedAt = null
        else state.carousel.publishedAt = state.status === 'show' ? new Date() : date

        await root.$store.dispatch('carousel/createCarousel', {
          data: {
            index: 0,
            title: state.carousel.title ?? undefined,
            publishedAt: state.status === 'keep' ? null : date ? date : new Date(),
            showType: state.carousel.showType.toString(),
            invisibleStampId: Number(state.carousel.InvisibleStamp.id),
          },
        })
        exit()
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const updateCarousel = async () => {
      try {
        if (!confirm('캐러셀을 수정합니다.')) return

        let date = null
        if (state.date && state.time) date = new Date(state.date + 'T' + state.time)
        else if (state.date) date = new Date(state.date + 'T00:00')

        if (state.status === 'keep') {
          state.carousel.publishedAt = null
          state.date = null
          state.time = null
        } else state.carousel.updatedAt = date ?? new Date()

        await root.$store.dispatch('carousel/updateCarousel', {
          ...state.carousel,
          id: Number(root.$route.params.uid),
          data: {
            title: state.carousel.title,
            publishedAt: state.status === 'keep' ? null : date ? date : new Date(),
            invisibleStampId: Number(state.carousel.InvisibleStamp.id),
            showType: state.carousel.showType,
          },
        })

        exit()
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const exit = () => {
      root.$router.push('/carousel')
    }

    onBeforeMount(async () => {
      if (root.$route.params.uid) getCarousel()
    })

    watch(
      () => state.showType,
      () => {
        if (state.showType.includes('column') && state.showType.includes('report')) {
          state.carousel.showType = 'all'
        } else if (state.showType.includes('report')) {
          state.carousel.showType = 'report'
        } else if (state.showType.includes('column')) {
          state.carousel.showType = 'column'
        }
      },
    )

    return {
      ...toRefs(state),
      createCarousel,
      updateCarousel,
      exit,
    }
  },
})
</script>
