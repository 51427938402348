<template>
  <v-combobox
    v-model="invisibleStamp"
    :filter="filter"
    :hide-no-data="!search"
    :items="listInvisibleStamp"
    :search-input.sync="search"
    item-text="name"
    item-value="id"
    outlined
    hide-selected
    hide-details
    :multiple="multiple"
    dense
  >
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading mr-3">Create</span>
        <v-chip color="#7f00ff" label small dark>
          {{ search }}
        </v-chip>
      </v-list-item>
    </template>

    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip v-if="item === Object(item)" v-bind="attrs" :color="item.color" :input-value="selected" label small dark>
        <span class="pr-2">
          {{ item.name }}
        </span>
        <v-icon small @click="parent.selectItem(item)">
          mdi-close
        </v-icon>
      </v-chip>
    </template>

    <template v-slot:item="{ index, item }">
      <v-text-field
        v-if="editing === item"
        v-model="editing.name"
        autofocus
        flat
        background-color="transparent"
        hide-details
        solo
        @click.stop
        @keyup.enter="edit(index, item)"
      />
      <v-chip v-else :color="item.color" dark label small>
        {{ item.name }}
      </v-chip>

      <v-spacer />

      <v-list-item-action @click.stop>
        <v-btn icon @click.stop.prevent="edit(index, item)">
          <v-icon>
            {{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}
          </v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>

<script>
import { defineComponent, reactive, watch, toRefs, onBeforeMount } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: Object,
    multiple: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { root, emit }) {
    const state = reactive({
      listInvisibleStamp: [],
      invisibleStamp: null,
      editing: null,
      editingIndex: -1,
      search: null,
    })

    const edit = async (index, item) => {
      if (!state.editing) {
        state.editing = item
        state.editingIndex = index
      } else {
        await root.$store.dispatch('stamp/updateInvisibleStamp', {
          id: Number(state.editing.id),
          name: state.editing.name,
        })

        state.editing = null
        state.editingIndex = -1
      }
    }

    const filter = (item, queryText, itemText) => {
      if (item.header) return false

      const hasValue = (val) => (val != null ? val : '')

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      )
    }

    onBeforeMount(async () => {
      try {
        state.invisibleStamp = props.value

        const listInvisibleStamp = await root.$store.dispatch('stamp/getListInvisibleStamp')

        state.listInvisibleStamp = [...listInvisibleStamp].sort((x, y) => x.name.localeCompare(y.name))
      } catch (error) {
        console.error(error)
      }
    })

    watch(
      () => state.invisibleStamp,
      () => {
        emit('input', state.invisibleStamp)
      },
      { deep: true },
    )

    watch(
      () => props.value,
      () => {
        state.invisibleStamp = props.value
      },
    )

    return {
      ...toRefs(state),
      edit,
      filter,
    }
  },
})
</script>
